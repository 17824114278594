
  import { deepCopy, localToUtc, utcToLocal } from '@/utils/general'
  import dayjs from 'dayjs'
  import { Component, Prop } from 'vue-property-decorator'
  import ConsignmentResults from '../common/ConsignmentResults.vue'
  import PurchasesResults from '../common/PurchasesResults.vue'
  import SupervisorDashboard from './SupervisorDashboard'

@Component({
  components: {
    ConsignmentResults,
    PurchasesResults,
  },
})
  export default class SupervisorConsignment extends SupervisorDashboard {
    @Prop({ default: true, type: Boolean }) showGeneralResult

    // Methods
    beforeCreate () {
      this.getData = async () => {
        this.$set(this.chartData.currentMonth, 'sales', await this.getSales(this.currentMonthFilters.startEndDate))
        this.$set(this.chartData.currentMonth, 'signedCredits', await this.getLoanEvaluationsAggregates(this.currentMonthFilters.signedCredits))
        this.$set(this.chartData.currentMonth, 'purchases', await this.getPurchases(this.currentMonthFilters.stockCreatedDate))

        this.$set(this.chartData.currentMonth, 'soldPurchases', await this.getPurchases(this.currentMonthFilters.soldPurchases))
        this.$set(this.chartData.currentMonth, 'soldSales', await this.getSoldSales(this.currentMonthFilters.soldSales))
        this.$set(this.chartData.currentMonth, 'utilityCredits', await this.getUtilityCredits(this.currentMonthFilters.utilityCredits))

        this.$set(this.chartData.currentMonth, 'inspections', await this.getInspections(this.currentMonthFilters.startEndDate))
        this.$set(this.chartData.currentMonth, 'appraisalLeads', await this.getLeads(this.currentMonthFilters.appraisalLeadDetail))
        this.$set(this.chartData.currentMonth, 'inspectionLeads', await this.getLeads(this.currentMonthFilters.inspectionLeadDetail))
        this.$set(this.chartData.currentMonth, 'negotiationLeads', await this.getLeads(this.currentMonthFilters.negotiationLeadDetail))
      }
    }

    fixPurchaseFields (records) {
      const corrected = deepCopy(records)
      for (let i = 0; i < corrected.length; i++) {
        const { auto, executive } = corrected[i]
        const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
        const executiveFullName = executive ? `${executive.person.firstName.trim()} ${executive.person.surname.trim()}` : 'SIN EJECUTIVO'
        corrected[i].stockCreated = dayjs(corrected[i].stockCreated).format('DD/MM/YYYY')
        if (executive) {
          corrected[i].executive.fullName = executiveFullName
        }
        if (corrected[i].stock.soldDate) {
          corrected[i].stock.soldDate = utcToLocal(corrected[i].stock.soldDate).format('DD/MM/YYYY')
        }
        corrected[i] = {
          ...corrected[i],
          carFullName,
          executiveFullName,
        }
      }

      return corrected
    }

    sortByStockCreated (records) {
      return records.sort((prev, next) => {
        if (dayjs(prev.stockCreated).isBefore(dayjs(next.stockCreated))) {
          return 1
        } else if (dayjs(prev.stockCreated).isAfter(dayjs(next.stockCreated))) {
          return -1
        }
        return 0
      })
    }

    sortByScheduled (records) {
      return records.sort((prev, next) => {
        const prevDate = dayjs(prev.scheduledDate)
        const nextDate = dayjs(next.scheduledDate)

        if (prevDate.isBefore(nextDate)) {
          return 1
        } else if (prevDate.isAfter(nextDate)) {
          return -1
        }
        return 0
      })
    }

    async normaliceData (records) {
      const leads = await this.getLeads(this.currentMonthFilters.inspectionLeadDetail)
      const inspections = this.getLeadInspections(leads.records)
      const Ids = inspections.filter(inspection => inspection.isConsignment).map(inspection => inspection.id)
      return records.map(record => {
        const {
          executive,
          executiveId,
          autoDescription,
          year,
          inspector,
          inspectorId,
          closingReason,
          status,
          scheduledDate,
          closingReasonDate,
        } = record
        const attributes = [
          'executive',
          'executiveId',
          'autoDescription',
          'year',
          'inspector',
          'inspectorId',
          'status',
          'scheduledDate',
          'closingReasonDate',
        ]
        const corrected = deepCopy(record)
        attributes.forEach(attribute => delete corrected[attribute])
        return {
          ...corrected,
          car: `${autoDescription} ${year}`,
          executive: {
            id: executiveId,
            name: executive?.toUpperCase() || 'SIN EJECUTIVO',
            scheduledDate: dayjs(localToUtc(scheduledDate)).format('DD/MM/YYYY'),
          },
          inspector: {
            id: inspectorId,
            name: inspector?.toUpperCase() || 'SIN INSPECTOR',
            closingReasonDate: dayjs(localToUtc(closingReasonDate)).format('DD/MM/YYYY'),
          },
          status: closingReason || status,
          subtitleIcon: Ids.includes(corrected.inspectionId)
            ? {
              icon: 'mdi-car-key',
              text: 'Consignación',
            } : undefined,
        }
      })
    }

  getLeadAppraisals (records) {
    const convertedRecords = this.addAcquisitionTypeBooleans(records)

    return convertedRecords
      .map(lead => {
        const appraisals = []
        const isConsignment = lead.isConsignment
        lead.deals?.forEach(deal => {
          deal.appraisals?.forEach(appraisal => {
            appraisals.push({ ...appraisal, isConsignment })
          })
        })
        return appraisals
      })
      .flat()
      .filter(appraisal => appraisal)
  }

  getLeadInspections (records) {
    const appraisals = this.getLeadAppraisals(records)
    return appraisals
      .map(appraisal => {
        const inspection = appraisal.inspection
        if (!inspection) return
        const { isConsignment } = appraisal
        return {
          ...inspection,
          isConsignment,
        }
      })
      .filter(inspection => inspection)
  }

  addAcquisitionTypeBooleans (records) {
    const pipelines = ['purchase', 'renewal', 'financed_renovation']
    return records
      .filter(record => pipelines.includes(record.pipeline.name))
      .map(record => {
        const deals = record.deals
        const dealAcquisitionTypes = deals.map(deal => {
          const appraisals = deal.appraisals
          const appraisalsAcquisitionTypes = appraisals.map(appraisal => {
            const lastAppraisedResponse = appraisal?.lastAppraisedResponse
            // const lastAppraisedConsignmentResponse = appraisal?.lastAppraisedConsignmentResponse
            const appraisalAgreedAmount = Number(appraisal?.agreedAmount)
            const purchaseAmount = Number(lastAppraisedResponse?.amount)
            // const consignmentAmount = lastAppraisedConsignmentResponse?.amount

            const negotiation = appraisal.inspection?.negotiation
            const negotiationAgreedAmount = negotiation?.agreedAmount

            const comparisonAmount = negotiationAgreedAmount || appraisalAgreedAmount

            const responses = appraisal.responses

            const isConsignment = comparisonAmount ? (comparisonAmount > purchaseAmount) : responses.some(response => response.responseType.name === 'expected_offer_consignment')
            const isPurchase = !isConsignment

            return { isConsignment, isPurchase }
          })
          return {
            isConsignment: appraisalsAcquisitionTypes.some(appraisal => appraisal.isConsignment),
            isPurchase: !appraisalsAcquisitionTypes.some(appraisal => appraisal.isConsignment),
          }
        })

        const isConsignment = dealAcquisitionTypes.some(deal => deal.isConsignment)

        return {
          ...record,
          isConsignment,
          isPurchase: !isConsignment,
        }
      })
  }

  transformDailyUtility (records) {
    return this.transformUtility(records, 'day')
  }

  transformMonthlyUtility (records) {
    return this.transformUtility(records, 'month')
  }

  transformUtility (records, granularity: 'day' | 'month', debugging = false) {
    const { getObjectAttribute } = this
    const { start, end } = granularity === 'day' ? this.dates : this.lastYearDates

    const recordsCopy = deepCopy(records)

    for (let i = 0; i < recordsCopy.length; i++) {
      this.$set(recordsCopy[i], 'records', recordsCopy[i].records?.records?.nodes || recordsCopy[i].records?.records || recordsCopy[i].records?.records || recordsCopy[i].records || [])
    }

    const purchasesRecords = recordsCopy?.filter(record => record.name === 'Compras')[0]
    const salesRecords = recordsCopy?.filter(record => record.name === 'Ventas')[0]
    const creditsRecords = recordsCopy?.filter(record => record.name === 'Créditos')[0]

    const ownStockSales = salesRecords.records.filter(sale => sale.saleOrder.deal.stock.stockDetail.acquisitionType.name === 'purchase')
    const ownPurchases = purchasesRecords.records.filter(purchase => purchase.acquisitionType.name === 'purchase')

    const consignedStockSales = salesRecords.records.filter(sale => sale.saleOrder.deal.stock.stockDetail.acquisitionType.name === 'consignment')
    const consignments = purchasesRecords.records.filter(purchase => purchase.acquisitionType.name === 'consignment')

    if (!purchasesRecords || !salesRecords || !creditsRecords) return

    let newDate = dayjs(start)
    const limitRange = [newDate]
    while (newDate.isBefore(dayjs(end))) {
      newDate = newDate.add(1, granularity)
      if (newDate.isBefore(dayjs(end))) {
        limitRange.push(newDate)
      }
    }
    if (debugging) console.log('DATES', limitRange)

    // Stock propio
    const ownStockUtility = limitRange.map(date => {
      const sales = ownStockSales.filter(record => dayjs(utcToLocal(getObjectAttribute(record, salesRecords.pathToDate))).isSame(date, granularity))
      const purchases = ownPurchases.filter(record => dayjs(utcToLocal(getObjectAttribute(record, purchasesRecords.pathToDate))).isSame(date, granularity))
      let utility = 0
      sales.forEach(sale => utility += getObjectAttribute(sale, salesRecords.pathToValue) || 0)
      purchases.forEach(purchase => utility -= getObjectAttribute(purchase, purchasesRecords.pathToValue) || 0)
      return {
        date: localToUtc(date).format('YYYY-MM-DDTHH:mm:ss'),
        utility,
      }
    })

    // Stock consignado
    const consignedStockUtility = limitRange.map(date => {
      const sales = consignedStockSales.filter(record => dayjs(utcToLocal(getObjectAttribute(record, salesRecords.pathToDate))).isSame(date, granularity))
      const purchases = consignments.filter(record => dayjs(utcToLocal(getObjectAttribute(record, purchasesRecords.pathToDate))).isSame(date, granularity))
      let utility = 0
      sales.forEach(sale => utility += getObjectAttribute(sale, salesRecords.pathToValue) || 0)
      purchases.forEach(purchase => utility -= getObjectAttribute(purchase, purchasesRecords.pathToValue) || 0)
      return {
        date: localToUtc(date).format('YYYY-MM-DDTHH:mm:ss'),
        utility,
      }
    })
    // Creditos
    const creditsUtility = limitRange.map(date => {
      const credits = creditsRecords.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, creditsRecords.pathToDate))).isSame(date, granularity))
      let utility = 0
      credits.forEach(credit => utility += getObjectAttribute(credit, creditsRecords.pathToValue))
      return {
        date: date.format('YYYY-MM-DD'),
        utility,
      }
    })

    return [
      {
        name: 'Stock propio',
        records: ownStockUtility,
        color: '#6185DB',
        pathToDate: ['date'],
        pathToValue: ['utility'],
        type: 'bar',
      },
      {
        name: 'Consignaciones',
        records: consignedStockUtility,
        color: '#FAD901',
        pathToDate: ['date'],
        pathToValue: ['utility'],
        type: 'bar',
      },
      {
        name: 'Créditos',
        records: creditsUtility,
        color: '#7D67BC',
        pathToDate: ['date'],
        pathToValue: ['utility'],
        type: 'bar',
      },
    ]
  }

  // Getters

  // Watchers
  }
