var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('purchases-results',{attrs:{"chart-data":_vm.chartData}})],1),_c('v-col',[_c('consignment-results',{attrs:{"chart-data":_vm.chartData}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Negocios último mes"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate},
                {category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate},
                {category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.currentMonthFilters.signedCredits},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate},\n                {category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate},\n                {category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: currentMonthFilters.signedCredits},\n              ])\n            }"}],attrs:{"data":[
              {
                records: _vm.chartData.currentMonth.purchases,
                name: 'Compras',
                pathToDate: ['stockCreated'],
                color: '#13CE66',
                type: 'bar',
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.currentMonth.purchases,
                name: 'Consignaciones',
                pathToDate: ['stockCreated'],
                color: '#FBDA01',
                type: 'bar',
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.currentMonth.signedCredits,
                name: 'Créditos',
                pathToDate: ['signedDate'],
                color: '#7D67BC',
                type: 'bar',
              },
              {
                records: _vm.chartData.currentMonth.sales,
                name: 'Ventas',
                pathToDate: ['soldDate'],
                color: '#6185DB',
                type: 'bar',
              },
            ],"prev-month-data":[],"dates":_vm.dates,"filter-zero-legends":false,"sort-legends":false}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Utilidad último mes"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries
                ([
                  {category: 'currentMonth', field: 'soldPurchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.soldPurchases},
                  {category: 'currentMonth', field: 'soldSales', queryMethod: _vm.getSoldSales, queryFilter: _vm.currentMonthFilters.soldSales},
                  {category: 'currentMonth', field: 'utilityCredits', queryMethod: _vm.getUtilityCredits, queryFilter: _vm.currentMonthFilters.utilityCredits},
                ])}),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries\n                ([\n                  {category: 'currentMonth', field: 'soldPurchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.soldPurchases},\n                  {category: 'currentMonth', field: 'soldSales', queryMethod: getSoldSales, queryFilter: currentMonthFilters.soldSales},\n                  {category: 'currentMonth', field: 'utilityCredits', queryMethod: getUtilityCredits, queryFilter: currentMonthFilters.utilityCredits},\n                ])}"}],attrs:{"data":[
              {
                records: _vm.chartData.currentMonth.soldPurchases,
                name: 'Compras',
                pathToDate: ['stock', 'soldDate'],
                color: '#13CE66',
                type: 'bar',
                pathToValue: ['totalCost'],
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.currentMonth.soldPurchases,
                name: 'Consignaciones',
                pathToDate: ['stockCreated'],
                color: '#FBDA01',
                type: 'bar',
                pathToValue: ['totalCost'],
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.currentMonth.soldSales,
                name: 'Ventas',
                pathToDate: ['saleOrder', 'deal', 'stock', 'soldDate'],
                color: '#6185DB',
                type: 'bar',
                pathToValue: ['amount'],
              },
              {
                records: _vm.chartData.currentMonth.utilityCredits,
                name: 'Créditos',
                pathToDate: ['evaluation', 'signedDate'],
                color: '#7D67BC',
                type: 'bar',
                pathToValue: ['fee'],
              },
            ],"prev-month-data":[],"data-transformer":_vm.transformDailyUtility,"sort-legends":false,"dates":_vm.dates,"is-currency":true}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Negocios último año"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries([
                {category: 'lastYear', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.lastYearFilters.stockCreatedDate},
                {category: 'lastYear', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.lastYearFilters.startEndDate},
                {category: 'lastYear', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.lastYearFilters.signedCredits},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries([\n                {category: 'lastYear', field: 'purchases', queryMethod: getPurchases, queryFilter: lastYearFilters.stockCreatedDate},\n                {category: 'lastYear', field: 'sales', queryMethod: getSales, queryFilter: lastYearFilters.startEndDate},\n                {category: 'lastYear', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: lastYearFilters.signedCredits},\n              ])\n            }"}],attrs:{"data":[
              {
                records: _vm.chartData.lastYear.purchases,
                name: 'Compras',
                pathToDate: ['stockCreated'],
                color: '#13CE66',
                type: 'bar',
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.lastYear.purchases,
                name: 'Consignaciones',
                pathToDate: ['stockCreated'],
                color: '#FBDA01',
                type: 'bar',
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.lastYear.signedCredits,
                name: 'Créditos',
                pathToDate: ['signedDate'],
                color: '#7D67BC',
                type: 'bar',
              },
              {
                records: _vm.chartData.lastYear.sales,
                name: 'Ventas',
                pathToDate: ['soldDate'],
                color: '#6185DB',
                type: 'bar',
              },
            ],"prev-month-data":[],"monthly":true,"dates":_vm.lastYearDates,"filter-zero-legends":false,"sort-legends":false}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Utilidad último año"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries
                ([
                  {category: 'lastYear', field: 'soldPurchases', queryMethod: _vm.getPurchases, queryFilter: _vm.lastYearFilters.soldPurchases},
                  {category: 'lastYear', field: 'soldSales', queryMethod: _vm.getSoldSales, queryFilter: _vm.lastYearFilters.soldSales},
                  {category: 'lastYear', field: 'utilityCredits', queryMethod: _vm.getUtilityCredits, queryFilter: _vm.lastYearFilters.utilityCredits},
                ])}),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries\n                ([\n                  {category: 'lastYear', field: 'soldPurchases', queryMethod: getPurchases, queryFilter: lastYearFilters.soldPurchases},\n                  {category: 'lastYear', field: 'soldSales', queryMethod: getSoldSales, queryFilter: lastYearFilters.soldSales},\n                  {category: 'lastYear', field: 'utilityCredits', queryMethod: getUtilityCredits, queryFilter: lastYearFilters.utilityCredits},\n                ])}"}],attrs:{"data":[
              {
                records: _vm.chartData.lastYear.soldPurchases,
                name: 'Compras',
                pathToDate: ['stock', 'soldDate'],
                color: '#13CE66',
                type: 'bar',
                pathToValue: ['totalCost'],
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.lastYear.soldPurchases,
                name: 'Consignación',
                pathToDate: ['stock', 'soldDate'],
                color: '#FBDA01',
                type: 'bar',
                pathToValue: ['totalCost'],
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.lastYear.soldSales,
                name: 'Ventas',
                pathToDate: ['saleOrder', 'deal', 'stock', 'soldDate'],
                color: '#6185DB',
                type: 'bar',
                pathToValue: ['amount'],
              },
              {
                records: _vm.chartData.lastYear.utilityCredits,
                name: 'Créditos',
                pathToDate: ['evaluation', 'signedDate'],
                color: '#7D67BC',
                type: 'bar',
                pathToValue: ['fee'],
              },
            ],"prev-month-data":[],"data-transformer":_vm.transformMonthlyUtility,"sort-legends":false,"monthly":true,"dates":_vm.lastYearDates,"is-currency":true}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Inspecciones por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"records-settings":_vm.chartData.settings.inspection,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceData,"path-to-category":['executive'],"path-to-category-title":['name'],"category-name":"ejecutivo"}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Consignas por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"consignment","default-target":20,"executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-category":['executive'],"path-to-category-title":['fullName'],"category-name":"ejecutivo","filtering-criteria":record => record.acquisitionType.name === 'consignment'}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Compras por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"quantity","default-target":20,"executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-category":['executive'],"category-name":"ejecutivo","path-to-category-title":['fullName'],"filtering-criteria":record => record.acquisitionType.name === 'purchase'}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Inspecciones agendadas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"records-settings":_vm.chartData.settings.inspection,"target-field":"quantity","executives":_vm.chartData.settings.executives,"icon":"mdi-car-search","data-transformer":_vm.normaliceData,"path-to-details":[
              {
                name: 'Ejecutivo',
                path: ['executive', 'name'],
                pathToComplement: ['executive', 'scheduledDate'],
              },
              {
                name: 'Inspector',
                path: ['inspector', 'name'],
                pathToComplement: ['inspector', 'closingReasonDate'],
              }
            ],"path-to-title":['car'],"path-to-subtitle":['registrationPlate'],"path-to-color":['status', 'color'],"sorting-method":_vm.sortByScheduled,"path-to-subtitle-icon":['subtitleIcon']}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Detalle de consignaciones"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"sorting-method":_vm.sortByStockCreated,"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"consignment","default-target":20,"executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-details":[
              {
                name: 'Ejecutivo',
                path: ['executiveFullName'],
                pathToComplement: ['stockCreated'],
              },
            ],"path-to-title":['carFullName'],"path-to-subtitle":['auto', 'registrationPlate'],"filtering-criteria":record => record.acquisitionType.name === 'consignment'}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Detalle de compras"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"sorting-method":_vm.sortByStockCreated,"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"quantity","default-target":20,"executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-details":[
              {
                name: 'Ejecutivo',
                path: ['executiveFullName'],
                pathToComplement: ['stockCreated'],
              },
            ],"path-to-title":['carFullName'],"path-to-subtitle":['auto', 'registrationPlate'],"filtering-criteria":record => record.acquisitionType.name === 'purchase'}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('dashboard-card',{attrs:{"title":"Consignas creadas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"consignment","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-category":['executive'],"path-to-category-title":['fullName'],"filtering-criteria":record => record.acquisitionType.name === 'consignment'}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('dashboard-card',{attrs:{"title":"Consignas vigentes"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"consignment","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-category":['executive'],"path-to-category-title":['fullName'],"use-total-as-target":true,"total-filter":record => record.acquisitionType.name === 'consignment',"filtering-criteria":record => {
              const statusesToRemove = ['sold', 'retired']
              return record.acquisitionType.name === 'consignment'
                && !statusesToRemove.includes(record.stock.status.status.name)
            }}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('dashboard-card',{attrs:{"title":"Consignas retiradas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"consignment","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-category":['executive'],"path-to-category-title":['fullName'],"use-total-as-target":true,"total-filter":record => record.acquisitionType.name === 'consignment',"filtering-criteria":record => {
              return record.acquisitionType.name === 'consignment'
                && record.stock.status.status.name === 'retired'
            }}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('dashboard-card',{attrs:{"title":"Consignas vendidas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"consignment","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-category":['executive'],"path-to-category-title":['fullName'],"use-total-as-target":true,"total-filter":record => record.acquisitionType.name === 'consignment',"filtering-criteria":record => {
              return record.acquisitionType.name === 'consignment'
                && record.stock.status.status.name === 'sold'
            }}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('dashboard-card',{attrs:{"title":"Consignas creadas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"sorting-method":_vm.sortByStockCreated,"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"consignment","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-details":[
              {
                name: 'Consignador',
                path: ['executiveFullName'],
                pathToComplement: ['stockCreated'],
              },
            ],"path-to-title":['carFullName'],"path-to-subtitle":['auto', 'registrationPlate'],"total-filter":record => record.acquisitionType.name === 'consignment',"filtering-criteria":record => record.acquisitionType.name === 'consignment'}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('dashboard-card',{attrs:{"title":"Consignas vigentes"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"sorting-method":_vm.sortByStockCreated,"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"consignment","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-details":[
              {
                name: 'Consignador',
                path: ['executiveFullName'],
                pathToComplement: ['stockCreated'],
              },
            ],"path-to-title":['carFullName'],"path-to-subtitle":['auto', 'registrationPlate'],"use-total-as-target":true,"comparison-sufix":"creadas","total-filter":record => record.acquisitionType.name === 'consignment',"filtering-criteria":record => {
              const statusesToRemove = ['sold', 'retired']
              return record.acquisitionType.name === 'consignment'
                && !statusesToRemove.includes(record.stock.status.status.name)
            }}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('dashboard-card',{attrs:{"title":"Consignas retiradas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"sorting-method":_vm.sortByStockCreated,"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"consignment","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-details":[
              {
                name: 'Consignador',
                path: ['executiveFullName'],
              },
            ],"path-to-title":['carFullName'],"path-to-subtitle":['auto', 'registrationPlate'],"use-total-as-target":true,"comparison-sufix":"creadas","total-filter":record => record.acquisitionType.name === 'consignment',"filtering-criteria":record => {
              return record.acquisitionType.name === 'consignment'
                && record.stock.status.status.name === 'retired'
            }}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('dashboard-card',{attrs:{"title":"Consignas vendidas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"sorting-method":_vm.sortByStockCreated,"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"consignment","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-details":[
              {
                name: 'Consignador',
                path: ['executiveFullName'],
                pathToComplement: ['stock', 'soldDate'],
              },
            ],"path-to-title":['carFullName'],"path-to-subtitle":['auto', 'registrationPlate'],"use-total-as-target":true,"comparison-sufix":"creadas","total-filter":record => record.acquisitionType.name === 'consignment',"filtering-criteria":record => {
              return record.acquisitionType.name === 'consignment'
                && record.stock.status.status.name === 'sold'
            }}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }